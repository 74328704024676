import AxiosClient from "./axiosClient";

const ConversationSessionApi = {
    overview: (params) => {
        const url = "conversation-session/overview"
        return AxiosClient.get(url, {params})
    },
    category: (params) => {
        const url = "conversation-session/category"
        return AxiosClient.get(url, {params})
    },
    missing : (params) => {
        const url = "conversation-session/missing"
        return AxiosClient.get(url, {params})
    },
    requested : (params) => {
        const url = "conversation-session/requested"
        return AxiosClient.get(url, {params})
    },
    detail : (params) => {
        const url = "conversation-session/detail"
        return AxiosClient.get(url, {params})
    },
    feedback : (params) => {
        const url = "conversation-session/feedback"
        return AxiosClient.get(url, {params})
    },
    negative : (params) => {
        const url = "conversation-session/negative"
        return AxiosClient.get(url, {params})
    },
    botMessage : (params) => {
        const url = "conversation-session/bot-message"
        return AxiosClient.get(url, {params})
    },
    agentMessage : (params) => {
        const url = "conversation-session/agent-message"
        return AxiosClient.get(url, {params})
    }

}
export default ConversationSessionApi