import AxiosClient from "./axiosClient";

const MessageApi = {
    getListMessage: (params) => {
        const url = "/message/list"
        return AxiosClient.get(url, {params})
    },
    staticMessage: (params) => {
        const url = "/message/statistic_message"
        return AxiosClient.get(url, {params})
    },
    staticFinance: (params) => {
        const url = "/message/statistic_money"
        return AxiosClient.get(url, {params})
    },
    note: (params) => {
        const url = "/message/note"
        return AxiosClient.post(url, params)
    },
    addTag : (params) => {
        const url = "/message/add_tag"
        return AxiosClient.put(url, params)
    },
    detachTag : (params) => {
        const url = "/message/detach_tag"
        return AxiosClient.put(url, params)
    },
    adminReport : (params) => {
        const url = "/message/admin-report"
        return AxiosClient.get(url, {params})
    },
    readMessage: (params) => {
        const url = "/message/read-message"
        return AxiosClient.put(url, params)
    },
}
export default MessageApi