import React, { createContext, useContext, useEffect, useCallback, useRef, useState } from 'react';
import { WEBSOCKET_COMMAND } from "../general/constant/appConstant";
import PreferenceKey from "../general/constant/preferenceKey";
import { useSelector } from 'react-redux';
import ToastHelper from '../general/helper/toastHelper';

const WebSocketContext = createContext(null);

export const useWebSocket = () => useContext(WebSocketContext);

export const WebSocketProvider = ({ children }) => {
    const socketRef = useRef(null);
    const [isConnected, setIsConnected] = useState(false);
    const messageListeners = useRef(new Map());
    const { account } = useSelector(state => state.account);
    const { chatbot = {} } = useSelector(state => state.chatbot);
    const { chatbotId } = chatbot;

    

    let retryCount = useRef(0);
    const maxRetries = 3;

    const handleWebSocketMessage = useCallback((messageData) => {
        try {
            const data = JSON.parse(messageData);
            messageListeners.current.forEach((listeners, type) => {
                listeners.forEach(callback => callback(data));
            });
        } catch (error) {
            console.error("Error parsing WebSocket message:", error);
        }
    }, []);

    const connect = useCallback(() => {
        if (retryCount.current >= maxRetries) {
            console.error("WebSocket connection failed after 3 attempts");
            return;
        }

        const ws = new WebSocket(process.env.REACT_APP_URL_WEBSOCKET);

        ws.onopen = () => {
            console.log("WebSocket connected");
            setIsConnected(true);
            socketRef.current = ws;
            retryCount.current = 0; // Reset lại số lần thử khi kết nối thành công

            const accessToken = localStorage.getItem(PreferenceKey.accessToken);
            if (accessToken && account?.accountId) {
                const tokenMessage = JSON.stringify({
                    command: WEBSOCKET_COMMAND.ACCOUNT_LOGIN,
                    accountId: account.accountId,
                    accessToken: accessToken,
                    chatbotId: chatbotId
                });
                ws.send(tokenMessage);
            }
        };

        ws.onclose = () => {
            console.log(`WebSocket disconnected. Attempting reconnect (${retryCount.current + 1}/${maxRetries})`);
            setIsConnected(false);
            retryCount.current++;

            if (retryCount.current < maxRetries) {
                setTimeout(connect, 3000);
            } else {
                ToastHelper.showWarning("Kiểm tra kết nối mạng hoặc refresh lại trình duyệt");
            }
        };

        ws.onerror = (error) => {
            console.error("WebSocket error:", error);
        };

        ws.onmessage = (event) => {
            handleWebSocketMessage(event.data);
        };

        socketRef.current = ws;
    }, []);

    useEffect(() => {
        connect();
        return () => {
            if (socketRef.current) {
                socketRef.current.close();
            }
        };
    }, [connect]);

    const send = useCallback((message) => {
        const attemptSend = (retry = 0) => {
            console.log("Sending message:", message);
            if (socketRef.current?.readyState === WebSocket.OPEN) {
                socketRef.current.send(JSON.stringify(message));
            } else if (retry < maxRetries) {
                console.warn(`WebSocket not connected. Retrying (${retry + 1}/${maxRetries})...`);
                connect();
                setTimeout(() => attemptSend(retry + 1), 1000); // Thử lại sau 1 giây
            } else {
                ToastHelper.showWarning("Kiểm tra kết nối mạng hoặc refresh lại trình duyệt");
                console.error("WebSocket is not connected after 3 attempts");
            }
        };
    
        attemptSend();
    }, [connect]);
    

    const addEventListener = useCallback((type, callback) => {
        const key = type || 'global';
        if (!messageListeners.current.has(key)) {
            messageListeners.current.set(key, new Set());
        }
        messageListeners.current.get(key).add(callback);

        return () => {
            const listeners = messageListeners.current.get(key);
            if (listeners) {
                listeners.delete(callback);
                if (listeners.size === 0) {
                    messageListeners.current.delete(key);
                }
            }
        };
    }, []);

    const updateChatbotId = useCallback((chatbotId) => {
        send({
            type: 'update_chatbot_id',
            chatbotId: chatbotId,
        });
    }, [send]);

    const removeToken = useCallback(() => {
        send({ type: 'remove_token' });
    }, [send]);

    const value = {
        socket: socketRef.current,
        isConnected,
        send,
        addEventListener,
        updateChatbotId,
        removeToken,
        connect
    };

    return (
        <WebSocketContext.Provider value={value}>
            {children}
        </WebSocketContext.Provider>
    );
};
