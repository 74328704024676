import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist";
import TestApi from "../api/testApi";


export const thunkGetListMessageTests = createAsyncThunk("settings/getListMessageTests", async (params, thunkAPI) => {
    return TestApi.getList(params)
})

export const thunkLoadMoreMessageTests = createAsyncThunk("settings/loadMore", async (params, thunkAPI) => {
    return TestApi.getList(params)
})

export const thunkAddTest = createAsyncThunk("settings/addTest", async (params, thunkAPI) => {
    return TestApi.addTest(params)
})

export const settingsSlice = createSlice({
    name: "settings", initialState: {
        selectedFacebookPage: {
            facebookPageId: null,
            pageName: null,
            chatbotId: null
        },
        isUploadingFile: false,
        isAddingTest: false,
        current: {
            testId: null,
            messageTests: []
        }
    }, reducers: {
        insertMessage: (state, action) => {
            const { payload } = action
            const { content, messageId } = payload
            const message = {
                messageTestId: messageId,
                conversationId: 0,
                customerId: null,
                accountId: null,
                content: content,
                messageType: "text",
                price: null,
                cost: null,
                datetime: new Date().toISOString(),
            }
            state.current.messageTests.push(payload)
        },
        setFacebookPage: (state, action) => {
            const { payload } = action
            const { facebookPage } = payload
            state.selectedFacebookPage = facebookPage
        },
        sendMessageToBot: (state, action) => {
            const { payload } = action
            const testId = state.current.testId
            const { content, accountId } = payload
            // SocketIoClient.emit("test", {content, testId, messageType: "text"})
            const message = {
                testId,
                accountId,
                content,
                messageType: "text",
                modelName: null,
                cost: null,
                datetime: (new Date()).toISOString()
            }
            state.current.messageTests.push(message)
        },
        addChunkMessage: (state, action) => {
            const { payload } = action
            const { chunk, messageId } = payload
            const index = state.current.messageTests.findIndex(message => message.messageTestId === messageId)
            if (index === -1) {
                const message = {
                    messageTestId: messageId,
                    conversationId: 0,
                    customerId: null,
                    accountId: null,
                    content: chunk,
                    messageType: "text",
                    price: null,
                    cost: null,
                    datetime: new Date().toISOString(),
                }
                state.current.messageTests.push(message)
                return;
            }

            state.current.messageTests[index].content = state.current.messageTests[index].content.concat("", chunk)

        }

    },
    extraReducers: builder => {
        builder.addCase(REHYDRATE, (state, action) => {
            state.isGettingListMessageTests = false
            state.isLoadingMoreMessageTests = false
        })

        builder.addCase(thunkGetListMessageTests.pending, state => {
            state.isGettingListMessageTests = true
        })
        builder.addCase(thunkGetListMessageTests.rejected, (state, action) => {
            state.isGettingListMessageTests = false
        })
        builder.addCase(thunkGetListMessageTests.fulfilled, (state, action) => {
            const { payload } = action
            const { test } = payload
            const { messageTests, testId } = test
            state.current.testId = testId
            state.current.messageTests = messageTests
            state.isGettingListMessageTests = false
        })

        builder.addCase(thunkLoadMoreMessageTests.pending, state => {
            state.isLoadingMoreMessageTests = true
        })
        builder.addCase(thunkLoadMoreMessageTests.rejected, (state, action) => {
            state.isLoadingMoreMessageTests = false
        })
        builder.addCase(thunkLoadMoreMessageTests.fulfilled, (state, action) => {
            const { payload } = action
            const { test } = payload
            const { messageTests } = test
            if (messageTests.length === 0) {
                state.isCanLoadMoreMessageTests = false
            }

            state.current.messageTests = [...messageTests, ...state.current.messageTests]
            state.isLoadingMoreMessageTests = false
        })

        builder.addCase(thunkAddTest.pending, state => {
            state.isAddingTest = true
        })
        builder.addCase(thunkAddTest.rejected, (state, action) => {
            state.isAddingTest = false
        })
        builder.addCase(thunkAddTest.fulfilled, (state, action) => {
            const { payload } = action
            const { test } = payload
            const { testId, messageTests } = test
            state.current.messageTests = messageTests
            state.current.testId = testId
            state.isAddingTest = false
        })
    }
})
export const { reducer, actions } = settingsSlice

export const { insertMessage, sendMessageToBot, setFacebookPage, addChunkMessage } = actions

export default reducer