import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { REHYDRATE } from "redux-persist"
import ConversationApi from "../api/conversationApi";
import MessageApi from "../api/messageApi";
import AppConstant, {
    CONVERSATION_STATUS,
    ConversationStatus,
    initTime
} from "../general/constant/appConstant";
import MessageReportedApi from "../api/messageReportedApi";
import { compareAsc } from "date-fns";
import MemberApi from "../api/memberApi";

export const thunkGetListConversation = createAsyncThunk("chat/getListConversation", async (params, thunkAPI) => {
    return ConversationApi.getListConversation(params)
})

export const thunkGetListConversationV2 = createAsyncThunk("chat/getListConversationV2", async (params, thunkAPI) => {
    return ConversationApi.getListConversationV3(params)
})
export const thunkGetListConversationV2Refesh = createAsyncThunk("chat/getListConversationV2Refresh", async (params, thunkAPI) => {
    return ConversationApi.getListConversationV3(params)
})


export const thunkGetConversation = createAsyncThunk("chat/getConversation", async (params, thunkAPI) => {
    return ConversationApi.getConversation(params)
})

export const thunkGetMoreConversation = createAsyncThunk("chat/loadMoreConversation", async (params, thunkAPI) => {
    return ConversationApi.getListConversation(params)
})

export const thunkGetListMessage = createAsyncThunk("chat/getListMessage", async (params, thunkAPI) => {
    return MessageApi.getListMessage(params)
})

export const thunkGetMoreMessages = createAsyncThunk("chat/loadMoreMessages", async (params, thunkAPI) => {
    return MessageApi.getListMessage(params)
})

export const thunkReportMessage = createAsyncThunk("chat/reportMessage", async (params, thunkAPI) => {
    return MessageReportedApi.add(params)
})

export const thunkEvaluateConversation = createAsyncThunk("chat/evaluateConversation", async (params, thunkAPI) => {
    return ConversationApi.evaluate(params)
})

export const thunkEvaluateConversationV2 = createAsyncThunk("chat/evaluateConversationV2", async (params, thunkAPI) => {
    return ConversationApi.evaluateV2(params)
})

export const thunkUpdateCustomerInformation = createAsyncThunk("chat/updateCustomerInformation", async (params, thunkAPI) => {
    return ConversationApi.updateCustomer(params)
})

export const thunkGetOnlineMembers = createAsyncThunk("chat/getOnlineMembers", async (params, thunkAPI) => {
    return MemberApi.getOnlineMember(params)
})

export const thunkNoteMessage = createAsyncThunk("chat/noteMessage", async (params, thunkAPI) => {
    return MessageApi.note(params)
})

export const thunkGetConversationByParam = createAsyncThunk("chat/getConversationByParam", async (params, thunkAPI) => {
    return ConversationApi.getConversation(params)
})

export const thunkAddTagToMessage = createAsyncThunk("chat/addTagToMessage", async (params, thunkAPI) => {
    return MessageApi.addTag(params);
})

export const thunkDetachTagFromMessage = createAsyncThunk("chat/detachTagFromMessage", async (params, thunkAPI) => {
    return MessageApi.detachTag(params);
})

export const thunkSearchListConversationV2 = createAsyncThunk("chat/searchListConversationV2", async (params, thunkAPI) => {
    return ConversationApi.getListConversationV2(params)
})

export const chatSlice = createSlice({
    name: "chat", initialState: {
        isCanLoadMoreConversation: true,
        isFindingConversationByParam: true,
        skip: 0,
        take: AppConstant.pageMessage,
        conversations: [],
        filteredConversations: [],
        handOverConversations: [],
        platform: null,
        status: null,
        platforms: [],
        statuses: [],
        accountId : null,
        startDate: initTime.startDate,
        endDate: initTime.endDate,
        onlineMembers: [],
        requestCustomer: { fullname: "" },
        currentConversation: {
            conversationId: null,
            account: null,
            updatedAt: null,
            status: ConversationStatus.normal,
            conversationSessions: [],
            customer: {
                customerId: null,
                facebookId: null,
                websiteId: null,
                tiktokId: null,
                shoppeId: null,
                zaloId: null,
                email: null,
                telephone: null,
                fullname: null,
                avatar: null,
                city: null,
                platform: null,
                ipAddress: null,
                tag: null
            },
            messages: [
                {
                    messageId: null,
                    conversationId: null,
                    customerId: null,
                    accountId: null,
                    content: null,
                    messageType: null,
                    modelName: null,
                    inputToken: null,
                    outputToken: null,
                    isReaded: false,
                    datetime: null,
                    account: null,
                    tags: []
                }
            ],
            unreadCnt: 0,
        },
        currentMessage: {
            messageId: null,
            conversationId: null,
            customerId: null,
            accountId: null,
            content: null,
            messageType: null,
            modelName: null,
            inputToken: null,
            outputToken: null,
            isReaded: false,
            datetime: null,
            account: null
        },
        searchedConversations: []
    },
    reducers: {
        // Function handle when you change conversation
        changeConversation: (state, action) => {
            const { payload: conversationId } = action;
        
            return {
                ...state,
                conversations: state.conversations.map((conversation) =>
                    conversation.conversationId === conversationId
                        ? { ...conversation, unreadCnt: 0 }
                        : conversation
                ),
                currentConversation: {
                    ...state.conversations.find((c) => c.conversationId === conversationId),
                    skip: state.conversations.find((c) => c.conversationId === conversationId)?.messages?.length ?? 0,
                    take: AppConstant.pageMessage,
                    unreadCnt: 0
                }
            };
        },

        addUnreadMessage: (state, action) => {
            const { payload: conversationId } = action;
            return {
                ...state,
                conversations: state.conversations.map((conversation) =>
                    conversation.conversationId === conversationId
                        ? { ...conversation, unreadCnt: conversation.unreadCnt + 1 }
                        : conversation
                )
            };
        },
        
        // Function handle when the message received
        addMessage: (state, action) => {
            const now = new Date()
            const { payload } = action
            const { message } = payload
            const { conversationId } = message
            const conversationIndex = state.conversations.findIndex((conversation) => conversation.conversationId === conversationId)

            // add message
            state.conversations[conversationIndex].messages.push(message)

            // change updated date
            state.conversations[conversationIndex].updatedAt = now

            // order conversations
            const conversation = state.conversations[conversationIndex]
            state.conversations.splice(conversationIndex, 1)
            state.conversations.unshift(conversation)
            if (conversationId === state.currentConversation.conversationId) {
                state.currentConversation.messages.push(message)
            } else {
                // If the message is of customer
                if (message.customerId) {
                    conversation.unreadCnt++;
                }
            }

        },
        // Function handle when get status botAI
        updateStatusBot: (state, action) => {
            const { payload } = action
            const { conversationId, enable, account, status } = payload
            const index = state.conversations.findIndex((item) => item.conversationId === conversationId)
            if (index === -1) {
                return;
            }

            // update account and enable
            state.conversations[index].account = enable ? null : account
            state.conversations[index].controlledAccount = enable ? null : account
            state.conversations[index].waitingAccount = enable ? state.conversations[index].waitingAccount : null
            state.conversations[index].enable = !!enable

            if (!status) {
                return;
            }

            const isBelongStatus = state.statuses.includes(status)

            // remove conversation
            if (!isBelongStatus) {
                state.conversations.splice(index, 1)

                const newConversations = state.conversations
                if (state.currentConversation?.conversationId === conversationId) {
                    if (newConversations.length !== 0) {
                        state.currentConversation = newConversations[0]
                    } else {
                        state.currentConversation = {
                            conversationId: null,
                            customer: { customerId: null, },
                            messages: [],
                        }
                    }
                }
            }
        },
        // Function handle when get status botAI
        updateStatusBots: (state, action) => {
            const { payload } = action
            const { conversations } = payload
            const conversationStates = state.conversations
            conversationStates.forEach((conversation, index) => {
                const { conversationId } = conversation
                const subIndex = conversations.findIndex((item) => item.conversationId === conversationId)
                if (subIndex !== -1) {
                    const {
                        account,
                        enable,
                        controlledAccount,
                        waitingAccount
                    } = conversations[subIndex]
                    state.conversations[index].account = account
                    state.conversations[index].enable = enable
                    state.conversations[index].controlledAccount = controlledAccount
                    state.conversations[index].waitingAccount = waitingAccount
                }
            })
        },
        updateStatusBotByConversationId: (state, action) => {
            const { payload } = action
            const { conversationId, enable, account, status } = payload
            const index = state.conversations.findIndex((item) => item.conversationId === conversationId)
            if (index === -1) {
                return;
            }

            // update account and enable
            state.conversations[index].account = enable ? null : account
            state.conversations[index].controlledAccount = enable ? null : account
            state.conversations[index].waitingAccount = enable ? state.conversations[index].waitingAccount : null
            state.conversations[index].enable = !!enable
        },
        // Function handle when data from mark to read message
        updateUnreadCnt: (state, action) => {
            const { payload } = action
            const { conversationId } = payload
            const conversationIndex = state.conversations.findIndex((conversation) => conversation.conversationId === conversationId)
            state.conversations[conversationIndex].unreadCnt = 0
            if (conversationId === state.currentConversation.conversationId) {
                state.currentConversation.unreadCnt = 0
            }
        },
        // function when selected message
        setCurMessage: (state, action) => {
            const { payload } = action
            const { messageId } = payload
            state.currentMessage = state.currentConversation.messages.find((item) => item.messageId === messageId)
        },
        // function active load more conversation
        activeLoadMoreConversations: (state, action) => {
            state.isCanLoadMoreConversation = true
        },
        // function which update platform or status of the conversation
        changeParams: (state, action) => {
            const { payload } = action
            const { platform, status, startDate, endDate, platforms, statuses, keyword, accountId } = payload
            state.platform = platform
            state.status = status
            state.keyword = keyword ? keyword : ""
            state.startDate = startDate ? new Date(startDate) : null;
            state.endDate = endDate ? new Date(endDate) : null;
            state.platforms = platforms
            state.statuses = statuses
            state.accountId = accountId
        },
        // function when customer request to chat directly
        requestToChatDirect(state, action) {
            const { payload } = action
            const { conversationId } = payload
            const index = state.conversations.findIndex((conversation) => conversation.conversationId === conversationId)
            if (index !== -1) {
                const conversation = state.conversations[index]
                state.conversations.splice(index, 1)
                state.conversations.unshift(conversation)
            }
        },
        addHandOverConversation(state, action) {
            const { payload } = action
            const { account, customer, conversationId } = payload
            const handOverConversation = { account, customer, conversationId }
            const index = state.handOverConversations.findIndex((item) => item.conversationId === conversationId)
            if (index === -1) {
                state.handOverConversations.push(handOverConversation)
            }
        },
        deleteHandOverConversation(state, action) {
            const { payload } = action
            const { conversationId } = payload
            const index = state.handOverConversations.findIndex((item) => item.conversationId === conversationId)
            state.handOverConversations.splice(index, 1)
        },
        updateRequestSupport(state, action) {
            const { payload } = action
            const { conversationId } = payload
            const index = state.conversations.findIndex((item) => item.conversationId === conversationId)
            if (index === -1) {
                return
            }
            const { account } = payload
            state.conversations[index].waitingAccount = account
        },
        setRequestCustomer(state, action) {
            const { payload } = action
            const { customer } = payload
            state.requestCustomer = customer
        },
        setConversationByParam(state, action) {
            const { payload } = action
            state.isFindingConversationByParam = !!payload
        },
        changeStatusConversation(state, action) {
            const { payload } = action
            const { conversationId, curStatus } = payload
            const index = state.conversations.findIndex((item) => item.conversationId === conversationId)
            if (index === -1) {
                return
            }
            const isBelongStatus = state.statuses.includes(curStatus)
            if (isBelongStatus) {
                switch (curStatus) {
                    case CONVERSATION_STATUS.processed:
                        state.conversations[index].enable = true
                        state.conversations[index].controlledAccount = null
                        state.conversations[index].waitingAccount = null
                        break
                    default:
                }
            } else {
                state.conversations.splice(index, 1)
            }

        },
        updateCurrentConversation(state, action) {
            const { payload } = action
            const { conversationId } = payload

            const { account, status } = payload

            try {
                const index = state.conversations.findIndex((item) => item.conversationId === conversationId)
                if (index !== -1) {
                    state.conversations[index].status = status
                    const length = state.conversations[index].conversationSessions.length
                    state.conversations[index].conversationSessions[length - 1].account = account
                }

                if(conversationId === state.currentConversation.conversationId){
                    state.currentConversation.status = status
                    const length = state.currentConversation.conversationSessions.length
                    state.currentConversation.conversationSessions[length - 1].account = account
                }
            }
            catch (error) {

            }
        },

    },
    extraReducers: builder => {
        builder.addCase(REHYDRATE, (state, action) => {
            state.isGettingListConversation = false
            state.isGettingConversation = false
            state.isGettingListMessage = false
            state.isLoadingMoreMessage = false
            state.isLoadingMoreConversation = false
            state.isReportingMessage = false
            state.isEvaluatingConversation = false
            state.isUpdatingCustomer = false
            state.isGettingOnlineMembers = false
            state.isNottingMessage = false
        })

        builder.addCase(thunkGetListConversation.pending, state => {
            state.isGettingListConversation = true
        })
        builder.addCase(thunkGetListConversation.rejected, (state, action) => {
            state.isGettingListConversation = false
        })
        builder.addCase(thunkGetListConversation.fulfilled, (state, action) => {
            state.isGettingListConversation = false
            const { payload } = action
            const { conversations, platforms, statuses } = payload
            const isBelongStatus = statuses.join(",") === state.statuses.join(",")
            const isBelongPlatform = platforms.join(",") === state.platforms.join(",")

            //check conversation belongs current list conversations
            if (!isBelongStatus || !isBelongPlatform) {
                return;
            }

            const curConversations = state.conversations

            state.conversations = conversations.map((conversation) => {
                const { conversationId } = conversation
                const foundConversation = curConversations.find((conversation) => conversation.conversationId === conversationId)
                if (!!foundConversation) {
                    return { ...foundConversation, ...conversation }
                }
                return { ...conversation }
            })

            // choose first conversation
            if (conversations.length === 0) {
                state.currentConversation = {
                    conversationId: null,
                    customer: { customerId: null, },
                    messages: [],
                }
            } else {
                state.currentConversation = conversations[0]

            }

        })

        builder.addCase(thunkGetListConversationV2.pending, state => {
            state.isGettingListConversation = true
        })
        builder.addCase(thunkGetListConversationV2.rejected, (state, action) => {
            state.isGettingListConversation = false
        })
        builder.addCase(thunkGetListConversationV2.fulfilled, (state, action) => {
            state.isGettingListConversation = false
            const { payload } = action
            const { conversations, platforms, statuses } = payload
            const isBelongStatus = statuses.join(",") === state.statuses.join(",")
            const isBelongPlatform = platforms.join(",") === state.platforms.join(",")


            if (!isBelongStatus || !isBelongPlatform) {
                return;
            }


            const curConversations = state.conversations

            state.conversations = conversations.reduce((acc, conversation) => {
                const { conversationId } = conversation;
                const foundConversation = curConversations.find(
                    (cur) => cur.conversationId === conversationId
                );

                if (state?.accountId !== null && conversation.conversationSessions[0]?.account?.accountId !== state.accountId) {
                    return acc; // Bỏ qua nếu không khớp accountId
                }
            
                acc.push(foundConversation ? { ...foundConversation, ...conversation } : { ...conversation });
                return acc;
            }, []);
            

            // choose first conversation
            if (conversations.length !== 0) {
                state.currentConversation = state.conversations[0] || {
                    conversationId: null,
                    customer: { customerId: null, },
                    messages: [],
                }
            } else {
                state.currentConversation = {
                    conversationId: null,
                    customer: { customerId: null, },
                    messages: [],
                }
            }

        })

        builder.addCase(thunkGetListConversationV2Refesh.fulfilled, (state, action) => {
            state.isGettingListConversation = false
            const { payload } = action
            const { conversations, platforms, statuses } = payload
            const isBelongStatus = statuses.join(",") === state.statuses.join(",")
            const isBelongPlatform = platforms.join(",") === state.platforms.join(",")

            if (!isBelongStatus || !isBelongPlatform) {
                return;
            }


            const curConversations = state.conversations

            state.conversations = conversations.reduce((acc, conversation) => {
                const { conversationId } = conversation;
                const foundConversation = curConversations.find(
                    (cur) => cur.conversationId === conversationId
                );

                if (state.accountId !== null && conversation.conversationSessions[0]?.account?.accountId !== state.accountId) {
                    return acc; // Bỏ qua nếu không khớp accountId
                }
            
                acc.push(foundConversation ? { ...foundConversation, ...conversation } : { ...conversation });
                return acc;
            }, []);

            if(state.conversations.length === 0){
                state.currentConversation = {
                    conversationId: null,
                    customer: { customerId: null, },
                    messages: [],
                }
            }
        })

        builder.addCase(thunkGetMoreConversation.pending, state => {
            state.isLoadingMoreConversation = true
        })
        builder.addCase(thunkGetMoreConversation.rejected, (state, action) => {
            state.isLoadingMoreConversation = false
        })
        builder.addCase(thunkGetMoreConversation.fulfilled, (state, action) => {
            const { payload } = action
            const { conversations } = payload

            // If conversations.length === 0 don't allow load more conversation
            if (conversations.length === 0) {
                state.isCanLoadMoreConversation = false
            }
            state.conversations = [...state.conversations, ...conversations]
            state.isLoadingMoreConversation = false
        })

        builder.addCase(thunkGetListMessage.pending, state => {
            state.isGettingListMessage = true
        })
        builder.addCase(thunkGetListMessage.rejected, (state, action) => {
            state.isGettingListMessage = false
        })
        builder.addCase(thunkGetListMessage.fulfilled, (state, action) => {
            const { payload } = action
            const { messages } = payload
            state.currentConversation.messages = messages
            state.isGettingListMessage = false
        })

        builder.addCase(thunkGetMoreMessages.pending, state => {
            state.isLoadingMoreMessage = true
        })
        builder.addCase(thunkGetMoreMessages.rejected, (state, action) => {
            state.isLoadingMoreMessage = false
        })
        builder.addCase(thunkGetMoreMessages.fulfilled, (state, action) => {
            const { payload } = action
            const { messages } = payload
            if (messages.length > 0) {
                const { conversationId } = messages[0]
                const conversationIndex = state.conversations.findIndex((item) => item.conversationId === conversationId)
                if (conversationIndex === -1) {
                    return
                }

                state.conversations[conversationIndex].messages = [...messages, ...state.conversations[conversationIndex].messages]
                if (conversationId === state.currentConversation.conversationId) {
                    state.currentConversation.messages = [...messages, ...state.currentConversation.messages,]
                }
            } else {
                const { conversationId } = state.currentConversation
                const conversationIndex = state.conversations.findIndex((item) => item.conversationId === conversationId)

                // disable load more when scroll
                if (conversationIndex !== -1) {
                    const curValue = state.conversations[conversationIndex]
                    state.conversations[conversationIndex] = { ...curValue, isCanLoadMore: false }
                }
            }
            state.isLoadingMoreMessage = false
        })

        builder.addCase(thunkReportMessage.pending, state => {
            state.isReportingMessage = true
        })
        builder.addCase(thunkReportMessage.rejected, (state, action) => {
            state.isReportingMessage = false
        })
        builder.addCase(thunkReportMessage.fulfilled, (state, action) => {
            state.isReportingMessage = false
        })

        builder.addCase(thunkGetConversation.pending, state => {
            state.isGettingConversation = true
        })
        builder.addCase(thunkGetConversation.rejected, (state, action) => {
            state.isGettingConversation = false
        })
        builder.addCase(thunkGetConversation.fulfilled, (state, action) => {
            const { payload } = action
            const { conversation } = payload
            const { status, customer, conversationId, updatedAt } = conversation
            const { platform } = customer
            const isBelongStatus = state.statuses.includes(status)
            const isBelongPlatform = state.platforms.includes(platform)
            const isBelongRangTime = (compareAsc(updatedAt, state.startDate) === 1 || compareAsc(updatedAt, state.startDate) === 0) && (compareAsc(state.endDate, updatedAt) === 1 || compareAsc(state.endDate, updatedAt) === 0)
            const conversations = state.conversations

            const index = conversations.findIndex((item) => item.conversationId === conversationId)
            //check conversation belongs current list conversations
            if (isBelongStatus && isBelongPlatform && isBelongRangTime && index === -1) {
                state.conversations.unshift(conversation)
            }

            state.isGettingConversation = false
        })

        builder.addCase(thunkEvaluateConversation.pending, state => {
            state.isEvaluatingConversation = true
        })
        builder.addCase(thunkEvaluateConversation.rejected, (state, action) => {
            state.isEvaluatingConversation = false
        })
        builder.addCase(thunkEvaluateConversation.fulfilled, (state, action) => {
            const { payload } = action
            const { conversation } = payload
            const { conversationId } = conversation
            const index = state.conversations.findIndex((item) => item.conversationId === conversationId)
            if (index !== -1) {
                const preValue = state.conversations[index]
                state.conversations[index] = { ...preValue, ...conversation }
            }
            state.isEvaluatingConversation = false
        })

        builder.addCase(thunkUpdateCustomerInformation.pending, state => {
            state.isUpdatingCustomer = true
        })
        builder.addCase(thunkUpdateCustomerInformation.rejected, (state, action) => {
            state.isUpdatingCustomer = false
        })
        builder.addCase(thunkUpdateCustomerInformation.fulfilled, (state, action) => {
            const { payload } = action
            const { conversationId, customer } = payload
            const index = state.conversations.findIndex((item) => item.conversationId === conversationId)
            if (index !== -1) {
                const preCustomer = state.conversations[index].customer
                state.conversations[index].customer = { ...preCustomer, ...customer }
            }
            state.isUpdatingCustomer = false
        })

        builder.addCase(thunkEvaluateConversationV2.pending, state => {
            state.isEvaluatingConversation = true
        })
        builder.addCase(thunkEvaluateConversationV2.rejected, (state, action) => {
            state.isEvaluatingConversation = false
        })
        builder.addCase(thunkEvaluateConversationV2.fulfilled, (state, action) => {
            state.isEvaluatingConversation = false
        })

        builder.addCase(thunkGetOnlineMembers.pending, state => {
            state.isGettingOnlineMembers = true
        })
        builder.addCase(thunkGetOnlineMembers.rejected, (state, action) => {
            state.isGettingOnlineMembers = false
        })
        builder.addCase(thunkGetOnlineMembers.fulfilled, (state, action) => {
            const { payload } = action
            const { members } = payload
            state.onlineMembers = members
            state.isGettingOnlineMembers = false
        })

        builder.addCase(thunkNoteMessage.pending, state => {
            state.isNottingMessage = true
        })
        builder.addCase(thunkNoteMessage.rejected, (state, action) => {
            state.isNottingMessage = false
        })
        builder.addCase(thunkNoteMessage.fulfilled, (state, action) => {
            state.isNottingMessage = false
        })

        builder.addCase(thunkGetConversationByParam.fulfilled, (state, action) => {
            const { payload } = action
            const { conversation } = payload
            const conversations = [conversation]
            state.currentConversation = conversation

            const curConversations = state.conversations

            state.conversations = conversations.map((conversation) => {
                const { conversationId } = conversation
                const foundConversation = curConversations.find((conversation) => conversation.conversationId === conversationId)
                if (!!foundConversation) {
                    return { ...foundConversation, ...conversation }
                }
                return { ...conversation }
            })

        })


        builder.addCase(thunkAddTagToMessage.fulfilled, (state, action) => {
            const { tag } = action.payload.message;
            const { messageId, conversationId } = state.currentMessage;
            const conversationIndex = state.conversations.findIndex(
                (item) => item.conversationId === conversationId
            );
            const messageIndex = state.conversations[conversationIndex].messages.findIndex(item => item.messageId === messageId);
            const updateMessageTags = (messages) => {
                if (messageIndex !== -1) {
                    const message = messages[messageIndex];
                    //check condition to avoid change other conversation
                    if (messageId === message?.messageId) {
                        if (message.tags !== undefined) {
                            message.tags.push( tag );
                        } else {
                            message.tags = [tag];
                        }
                    } else {
                        return;
                    }
                }
            };
            if (conversationIndex !== -1) {
                updateMessageTags(state.conversations[conversationIndex].messages);
            }

            updateMessageTags(state.currentConversation.messages);
        });


        builder.addCase(thunkDetachTagFromMessage.fulfilled, (state, action) => {
            const { tagId } = action.payload.message;
            const { messageId, conversationId } = state.currentMessage;

            const conversationIndex = state.conversations.findIndex(
                (item) => item.conversationId === conversationId
            );
            const messageIndex = state.conversations[conversationIndex].messages.findIndex(item => item.messageId === messageId);
            const detachTagFromMessage = (messages) => {


                if (messageIndex !== -1) {
                    const message = messages[messageIndex];
                    //check condition to avoid change other conversation
                    if (messageId === message?.messageId) {
                        const tagIndex = message.tags.findIndex(item => item.tagId === tagId);
                        if (tagIndex !== -1) {
                            message.tags.splice(tagIndex, 1);
                        }
                    } else {
                        return;
                    }
                }
            };
            if (conversationIndex !== -1) {
                detachTagFromMessage(state.conversations[conversationIndex].messages);
            }
            detachTagFromMessage(state.currentConversation.messages);
        });

        builder.addCase(thunkSearchListConversationV2.pending, state => {
        })
        builder.addCase(thunkSearchListConversationV2.rejected, (state, action) => {
        })
        builder.addCase(thunkSearchListConversationV2.fulfilled, (state, action) => {
            const { payload } = action
            const { conversations, platforms, statuses } = payload
            const isBelongStatus = statuses.join(",") === state.statuses.join(",")
            const isBelongPlatform = platforms.join(",") === state.platforms.join(",")

            //check conversation belongs current list conversations
            if (!isBelongStatus || !isBelongPlatform) {
                return;
            }

            state.searchedConversations = conversations
        })

    }
})
export const { reducer, actions } = chatSlice

export const {
    changeConversation,
    addMessage,
    updateStatusBot,
    updateStatusBots,
    updateUnreadCnt,
    setCurMessage,
    changeParams,
    activeLoadMoreConversations,
    requestToChatDirect,
    addHandOverConversation,
    deleteHandOverConversation,
    updateRequestSupport,
    setRequestCustomer,
    changeStatusConversation,
    setConversationByParam,
    addTagToMessage,
    detachTagFromMessage,
    updateCurrentConversation,
    addUnreadMessage
} = actions

export default reducer