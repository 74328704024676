import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import {REHYDRATE} from "redux-persist"
import AuthApi from "../api/authApi";
import PreferenceKey from "../general/constant/preferenceKey";
import {updateAccessToken} from "../api/axiosClient";
import UserHelper from "../general/helper/userHelper";

//sign in
export const thunkSignIn = createAsyncThunk("auth/signIn", async (params, thunkAPI) => {
    return AuthApi.signIn(params);
})

export const thunkSignUp = createAsyncThunk("auth/signUp", async (params, thunkAPI) => {
    return AuthApi.signUp(params)
})

export const thunkConfirm = createAsyncThunk("auth/confirm", async (params, thunkAPI) => {
    return AuthApi.confirm(params)
})

export const thunkSignOut = createAsyncThunk("auth/signOut", async (params, thunkAPI) => {
    return AuthApi.signOut(params)
})

export const thunkRefreshToken = createAsyncThunk("auth/refreshToken", async (params, thunkAPI) => {
    return AuthApi.refreshToken(params)
})

export const thunkGoogleSignIn = createAsyncThunk("auth/google/data", async (params, thunkAPI) => {
    return AuthApi.dataGoogleCallback(params);
})

export const authSlice = createSlice({
    name: "auth", initialState: {
        current: {},
    }, reducers: {
        signOut: (state, action) => {
            UserHelper.signOut()
            window.location.href = "/auth/sign-in"
        }
    },
    extraReducers: builder => {
        builder.addCase(REHYDRATE, (state, action) => {
            state.isSigningIn = false
            state.isSigningUp = false
            state.isConfirming = false
            state.isSigningOut = false
            state.isRefreshingToken = false
        })

        builder.addCase(thunkSignIn.pending, state => {
            state.isSigningIn = true
        })
        builder.addCase(thunkSignIn.rejected, (state, action) => {
            state.isSigningIn = false
        })
        builder.addCase(thunkSignIn.fulfilled, (state, action) => {
            state.isSigningIn = false
        })
        builder.addCase(thunkSignUp.pending, state => {
            state.isSigningUp = true
        })
        builder.addCase(thunkSignUp.rejected, (state, action) => {
            state.isSigningUp = false
        })
        builder.addCase(thunkSignUp.fulfilled, (state, action) => {
            state.isSigningUp = false
        })

        builder.addCase(thunkConfirm.pending, state => {
            state.isConfirming = true
        })
        builder.addCase(thunkConfirm.rejected, (state, action) => {
            state.isConfirming = false
        })
        builder.addCase(thunkConfirm.fulfilled, (state, action) => {
            state.isConfirming = false
        })

        builder.addCase(thunkSignOut.pending, state => {
            state.isSigningOut = true
        })
        builder.addCase(thunkSignOut.rejected, (state, action) => {
            state.isSigningOut = false
        })
        builder.addCase(thunkSignOut.fulfilled, (state, action) => {
            UserHelper.signOut()
            window.location.href = "/auth/sign-in"
            state.isSigningOut = false
        })

        builder.addCase(thunkRefreshToken.pending, state => {
            state.isRefreshingToken = true
        })
        builder.addCase(thunkRefreshToken.rejected, (state, action) => {
            state.isRefreshingToken = false
        })
        builder.addCase(thunkRefreshToken.fulfilled, (state, action) => {
            const {payload} = action
            const {accessToken} = payload
            const {token} = accessToken
            localStorage.setItem(PreferenceKey.accessToken, token)
            updateAccessToken(token)
            // updateSocketIOToken(token)
            state.isRefreshingToken = false
        })

        builder.addCase(thunkGoogleSignIn.pending, state => {
            state.isSigningIn = true
        })
        builder.addCase(thunkGoogleSignIn.rejected, (state, action) => {
            state.isSigningIn = false
        })
        builder.addCase(thunkGoogleSignIn.fulfilled, (state, action) => {
            state.isSigningIn = false
            const {payload} = action
            const {accessToken, refreshToken} = payload
            const {token} = accessToken
            localStorage.setItem(PreferenceKey.accessToken, token)
            localStorage.setItem(PreferenceKey.refreshToken, refreshToken.token)
            updateAccessToken(token)
            // updateSocketIOToken(token)
        })

    }
})
export const {reducer, actions} = authSlice


export const {signOut} = actions
export default reducer